<template>
  <div>

    <HeaderMain />

    <!-- ДОКУМЕНТЫ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pb-0 pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum" style="grid-column-gap: unset;">
          <!-- блок 1 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: #F3F3F3;
               position: relative;
               overflow: hidden;
            ">
            <div class="w-layout-vflex tittle"
                 style="
                 z-index: 1;
                 justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title">
                <h1 class="heading">Документы</h1>
              </div>
              <div class="w-layout-vflex flex-good"
                   style="flex-flow: row;"
              >
                <h1 class="text-mini white">Важная<br>информация</h1>
              </div>
            </div>

            <!-- Картинка в углу -->
            <img src="../../../../../public/images/document_box.png" alt="money"
                 style="position: absolute;
                 bottom: 0;
                 max-height: 75%;
                 right: 0;"
            >
          </div>
          <!-- блок 2 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum right w-100"
               style="
               border-radius: unset;
               padding: 0 10px;
            ">

            <div class="section w-100 h-100">
              <div class="grid-container-4">

                <!-- Первый блок -->
                <div @click="goToDocument('loan-rules')" class="grid-item-4 gray-card" style="padding: 25px;">
                  <div class="w-layout-vflex tittle"
                       style="
                 z-index: 1;
                 justify-content: space-between;"
                  >
                    <div class="w-layout-vflex heading-title">
                      <div>
                        <img class="img-40" src="../../../../../public/images/documents/pin.svg" alt="pin" style="margin-bottom: 20px;">
                        <h1 class="text-mini-3">Правила<br>предоставления займов</h1>
                      </div>
                    </div>
                    <div class="w-layout-vflex flex-good"
                         style="flex-flow: row;"
                    >
                      <img class="img-40" src="../../../../../public/images/documents/pdf.svg" alt="pdf">
                    </div>
                  </div>
                </div>

                <!-- Второй блок -->
                <div @click="goToDocument('general-terms-of-agreement')" class="grid-item-4 gray-card" style="padding: 25px;">
                  <div class="w-layout-vflex tittle"
                       style="
                          z-index: 1;
                          justify-content: space-between;"
                  >
                    <div class="w-layout-vflex heading-title">
                      <div>
                        <img class="img-40" src="../../../../../public/images/documents/pin.svg" alt="pin" style="margin-bottom: 20px;">
                        <h1 class="text-mini-3">Общие условия договора потребительского займа</h1>
                      </div>
                    </div>
                    <div class="w-layout-vflex flex-good"
                         style="flex-flow: row;"
                    >
                      <img class="img-40" src="../../../../../public/images/documents/pdf.svg" alt="pdf">
                    </div>
                  </div>
                </div>

                <!-- Третий блок -->
                <div @click="goToDocument('procedure-for-providing')" class="grid-item-4 gray-card" style="padding: 25px;">
                  <div class="w-layout-vflex tittle"
                       style="
                          z-index: 1;
                          justify-content: space-between;"
                  >
                    <div class="w-layout-vflex heading-title">
                      <div>
                        <img class="img-40" src="../../../../../public/images/documents/pin.svg" alt="pin" style="margin-bottom: 20px;">
                        <h1 class="text-mini-3">Информация о порядке предоставления услуг</h1>
                      </div>
                    </div>
                    <div class="w-layout-vflex flex-good"
                         style="flex-flow: row;"
                    >
                      <img class="img-40" src="../../../../../public/images/documents/pdf.svg" alt="pdf">
                    </div>
                  </div>
                </div>

                <!-- Четвёртый блок -->
                <div @click="goToDocument('credit-holidays')" class="grid-item-4 gray-card" style="padding: 25px;">
                  <div class="w-layout-vflex tittle"
                       style="
                          z-index: 1;
                          justify-content: space-between;"
                  >
                    <div class="w-layout-vflex heading-title">
                      <div>
                        <img class="img-40" src="../../../../../public/images/documents/pin.svg" alt="pin" style="margin-bottom: 20px;">
                        <h1 class="text-mini-3">Информация для заемщиков о кредитных каникулах</h1>
                      </div>
                    </div>
                    <div class="w-layout-vflex flex-good"
                         style="flex-flow: row;"
                    >
                      <img class="img-40" src="../../../../../public/images/documents/pdf.svg" alt="pdf">
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- КОЛОБОК В ТВОЁМ ТЕЛЕФОНЕ (2) -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               position: relative; overflow: hidden;
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: linear-gradient(135deg, #005BFA 0%, #005BFA 32%, #CFDCF0 90%);
               background-position: 50% 0;
               background-size: 150% 150%;">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100" style="z-index: 1;">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="color: #fff;">Колобок в твоём<br>телефоне</h1>
                  <img alt="install" src="../../../../../public/images/google_play.svg" class="install-image">
                </div>
              </div>

              <!-- Картинка в углу -->
              <img src="../../../../../public/images/money.png" alt="money"
                   class="money-width-2"
              >

              <!-- блок разных вариантов установки, появляется на мобилках -->
              <div class="w-layout-vflex flex-good hidden-install"
                   style="
                       align-items: end;
                       flex-flow: row;
                       z-index: 2;
                       justify-content: space-around;"
              >
                <div>
                  <img height="60px" alt="googleplay" src="../../../../../public/images/google_play.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderMain } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'Main',
  data() {
    return {
    };
  },
  components: {
    Footer,
    HeaderMain
  },
  created() {
  },
  computed: {
  },
  methods: {
    goToDocument(name) {
      this.$router.push('/documents/' + name);
    },
    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              console.log('jQuery loaded');

              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  console.log('Webflow loaded');
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },
    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="accordion.js"], script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    }
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>

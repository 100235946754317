var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('HeaderMain'),_c('div',{staticClass:"section color"},[_c('div',{staticClass:"container one w-container pb-0 pt-0",staticStyle:{"border-bottom":"none"},attrs:{"data-w-id":"8a817dec-33d8-ba64-0999-5e0ddc2f3a81"}},[_c('div',{staticClass:"w-layout-grid grid-2-colum",staticStyle:{"grid-column-gap":"unset"}},[_vm._m(0),_c('div',{staticClass:"grid-colum right w-100",staticStyle:{"border-radius":"unset","padding":"0 10px"},attrs:{"id":"w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc"}},[_c('div',{staticClass:"section w-100 h-100"},[_c('div',{staticClass:"grid-container-4"},[_c('div',{staticClass:"grid-item-4 gray-card",staticStyle:{"padding":"25px"},on:{"click":function($event){return _vm.goToDocument('loan-rules')}}},[_vm._m(1)]),_c('div',{staticClass:"grid-item-4 gray-card",staticStyle:{"padding":"25px"},on:{"click":function($event){return _vm.goToDocument('general-terms-of-agreement')}}},[_vm._m(2)]),_c('div',{staticClass:"grid-item-4 gray-card",staticStyle:{"padding":"25px"},on:{"click":function($event){return _vm.goToDocument('procedure-for-providing')}}},[_vm._m(3)]),_c('div',{staticClass:"grid-item-4 gray-card",staticStyle:{"padding":"25px"},on:{"click":function($event){return _vm.goToDocument('credit-holidays')}}},[_vm._m(4)])])])])])])]),_vm._m(5),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-colum left w-100",staticStyle:{"padding":"25px","padding-bottom":"35px","border-radius":"unset","background-color":"#F3F3F3","position":"relative","overflow":"hidden"},attrs:{"id":"w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc"}},[_c('div',{staticClass:"w-layout-vflex tittle",staticStyle:{"z-index":"1","justify-content":"space-between"}},[_c('div',{staticClass:"w-layout-vflex heading-title"},[_c('h1',{staticClass:"heading"},[_vm._v("Документы")])]),_c('div',{staticClass:"w-layout-vflex flex-good",staticStyle:{"flex-flow":"row"}},[_c('h1',{staticClass:"text-mini white"},[_vm._v("Важная"),_c('br'),_vm._v("информация")])])]),_c('img',{staticStyle:{"position":"absolute","bottom":"0","max-height":"75%","right":"0"},attrs:{"src":require("../../../../../public/images/document_box.png"),"alt":"money"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-layout-vflex tittle",staticStyle:{"z-index":"1","justify-content":"space-between"}},[_c('div',{staticClass:"w-layout-vflex heading-title"},[_c('div',[_c('img',{staticClass:"img-40",staticStyle:{"margin-bottom":"20px"},attrs:{"src":require("../../../../../public/images/documents/pin.svg"),"alt":"pin"}}),_c('h1',{staticClass:"text-mini-3"},[_vm._v("Правила"),_c('br'),_vm._v("предоставления займов")])])]),_c('div',{staticClass:"w-layout-vflex flex-good",staticStyle:{"flex-flow":"row"}},[_c('img',{staticClass:"img-40",attrs:{"src":require("../../../../../public/images/documents/pdf.svg"),"alt":"pdf"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-layout-vflex tittle",staticStyle:{"z-index":"1","justify-content":"space-between"}},[_c('div',{staticClass:"w-layout-vflex heading-title"},[_c('div',[_c('img',{staticClass:"img-40",staticStyle:{"margin-bottom":"20px"},attrs:{"src":require("../../../../../public/images/documents/pin.svg"),"alt":"pin"}}),_c('h1',{staticClass:"text-mini-3"},[_vm._v("Общие условия договора потребительского займа")])])]),_c('div',{staticClass:"w-layout-vflex flex-good",staticStyle:{"flex-flow":"row"}},[_c('img',{staticClass:"img-40",attrs:{"src":require("../../../../../public/images/documents/pdf.svg"),"alt":"pdf"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-layout-vflex tittle",staticStyle:{"z-index":"1","justify-content":"space-between"}},[_c('div',{staticClass:"w-layout-vflex heading-title"},[_c('div',[_c('img',{staticClass:"img-40",staticStyle:{"margin-bottom":"20px"},attrs:{"src":require("../../../../../public/images/documents/pin.svg"),"alt":"pin"}}),_c('h1',{staticClass:"text-mini-3"},[_vm._v("Информация о порядке предоставления услуг")])])]),_c('div',{staticClass:"w-layout-vflex flex-good",staticStyle:{"flex-flow":"row"}},[_c('img',{staticClass:"img-40",attrs:{"src":require("../../../../../public/images/documents/pdf.svg"),"alt":"pdf"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-layout-vflex tittle",staticStyle:{"z-index":"1","justify-content":"space-between"}},[_c('div',{staticClass:"w-layout-vflex heading-title"},[_c('div',[_c('img',{staticClass:"img-40",staticStyle:{"margin-bottom":"20px"},attrs:{"src":require("../../../../../public/images/documents/pin.svg"),"alt":"pin"}}),_c('h1',{staticClass:"text-mini-3"},[_vm._v("Информация для заемщиков о кредитных каникулах")])])]),_c('div',{staticClass:"w-layout-vflex flex-good",staticStyle:{"flex-flow":"row"}},[_c('img',{staticClass:"img-40",attrs:{"src":require("../../../../../public/images/documents/pdf.svg"),"alt":"pdf"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section color"},[_c('div',{staticClass:"container one w-container pt-0",staticStyle:{"border-bottom":"none"},attrs:{"data-w-id":"8a817dec-33d8-ba64-0999-5e0ddc2f3a81"}},[_c('div',{staticClass:"w-layout-grid grid-1-colum",staticStyle:{"grid-column-gap":"unset"}},[_c('div',{staticClass:"grid-colum left w-100",staticStyle:{"position":"relative","overflow":"hidden","padding":"25px 30px 35px 25px","border-radius":"unset","background":"linear-gradient(135deg, #005BFA 0%, #005BFA 32%, #CFDCF0 90%)","background-position":"50% 0","background-size":"150% 150%"},attrs:{"id":"w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc"}},[_c('div',{staticClass:"w-layout-vflex tittle",staticStyle:{"justify-content":"space-between"}},[_c('div',{staticClass:"w-layout-vflex heading-title w-100",staticStyle:{"z-index":"1"}},[_c('div',{staticClass:"flex w-100 space-between",staticStyle:{"gap":"10px"}},[_c('h1',{staticClass:"heading",staticStyle:{"color":"#fff"}},[_vm._v("Колобок в твоём"),_c('br'),_vm._v("телефоне")]),_c('img',{staticClass:"install-image",attrs:{"alt":"install","src":require("../../../../../public/images/google_play.svg")}})])]),_c('img',{staticClass:"money-width-2",attrs:{"src":require("../../../../../public/images/money.png"),"alt":"money"}}),_c('div',{staticClass:"w-layout-vflex flex-good hidden-install",staticStyle:{"align-items":"end","flex-flow":"row","z-index":"2","justify-content":"space-around"}},[_c('div',[_c('img',{attrs:{"height":"60px","alt":"googleplay","src":require("../../../../../public/images/google_play.svg")}})])])])])])])])
}]

export { render, staticRenderFns }